import {
  MARK_SELECTED,
  SCORE_SELECTED,
  NEXT_ATTEMPT,
  CLEAR_SELECTED,
  UPDATE_STEP,
  SET_GAME_STATE,
  SET_STEP,
  SET_IS_PUZZLE_MODAL_OPEN,
  SHOW_MODAL,
  CLOSE_MODAL,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case MARK_SELECTED: {
      const newState = {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.type]: {
            text: action.payload.text,
            correct: action.payload.correct,
            selected: true,
          },
        },
      };
      action.syncState(newState);
      return newState;
    }
    case SCORE_SELECTED: {
      const newState = {
        ...state,
        correctAttempt: action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case CLEAR_SELECTED: {
      let selected = [];
      if (state.settings?.keepCorrectItems) {
        const jsonObject = state.selected;
        selected = Object.fromEntries(
          Object.entries(jsonObject).filter(([_, value]) => value.correct === true)
        );
      }

      const newState = {
        ...state,
        selected,
      };
      action.syncState(newState);
      return newState;
    }
    case NEXT_ATTEMPT: {
      const newState = {
        ...state,
        attemptNum: action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case SHOW_MODAL: {
      const newState = {
        ...state,
        showModal: true,
        ...action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case CLOSE_MODAL: {
      const newState = {
        ...state,
        showModal: false,
        modalText: '',
        modalTitle: '',
      };
      action.syncState(newState);
      return newState;
    }
    case UPDATE_STEP: {
      const newState = {
        ...state,
        step: action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case SET_GAME_STATE: {
      const newState = {
        ...state,
        ...action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case SET_STEP: {
      const newState = {
        ...state,
        step: action.payload,
      };
      action.syncState(newState);
      return newState;
    }
    case SET_IS_PUZZLE_MODAL_OPEN: {
      const newState = {
        ...state,
        isPuzzleModalOpen: action.payload,
      };
      action.syncState(newState);
      return newState;
    }

    default: {
      action.syncState(state);
      return state;
    }
  }
};
